import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'

export const AnimationPostTemplate = ({
  content,
  contentComponent,
  description,
  subheading,
  featuredimage,
  tags,
  title,
  helmet,
  next,
  previous
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            {next && <Link to={next.fields.slug} className="float-left">←{next.frontmatter.title}</Link>}
            {previous && <Link to={previous.fields.slug} className="float-right">{previous.frontmatter.title}→</Link>}
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <img src={featuredimage.childImageSharp.fluid.src} alt="aaaaa" />
            {/* <div
              className="featured-image-container margin-top-0"
              style={{
                backgroundImage: `url(${
                  !!featuredimage.childImageSharp ? featuredimage.childImageSharp.fluid.src : featuredimage
                })`,
              }}
            ></div> */}
            <h2>{subheading}</h2>
            <p>{description}</p>
            <PostContent content={content}>{content}</PostContent>
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h5><FontAwesomeIcon icon={faTags} /> Tags</h5>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`} className="button is-light is-small">{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

AnimationPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  subheading: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  helmet: PropTypes.object,
  next: PropTypes.object,
  previous: PropTypes.object
}

const AnimationPost = ({ data, pageContext }) => {
  const {next, previous} = pageContext
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AnimationPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Daily">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        subheading={post.frontmatter.subheading}
        featuredimage={post.frontmatter.featuredimage}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        next={next}
        previous={previous}
      />
    </Layout>
  )
}

AnimationPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default AnimationPost

export const pageQuery = graphql`
  query AnimationPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subheading
        description
        tags
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
